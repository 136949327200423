












































































































































































































































.book_outer_content {
  padding: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.book_list_container {
  flex: 2;
  padding-left: 20px;
  overflow-y: scroll;
  .book_item {
    height: 180px;
    display: flex;
    background: #ffffff;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    .book_img {
      width: 105px;
    }
    .book_right {
      flex: 2;
      padding-left: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .book_name {
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        width: 70%;
        -webkit-line-clamp: 2;
      }
      .book_study {
        font-size: 14px;
        color: #faad14;
      }
      .btn_study {
        font-size: 14px;
        width: 88px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        background: #1890ff;
        color: white;
        text-align: center;
        float: left;
      }
      .btn_conversion {
        background: white;
        color: #1890ff;
        margin-left: 10px;
        line-height: 30px;
        border: 1px solid #1890ff;
      }
    }
  }
}
// .book_list_content {
//   display: flex;
//   flex-wrap: wrap;
//   flex: 2;
//   overflow-y: scroll;
//   position: relative;
//   .book_item {
//     text-align: center;
//     margin-bottom: 20px;
//     .left {
//       .book_img {
//         width: 185px;
//         min-height: 248.61px;
//       }
//     }
//     .right {
//       margin-top: 10px;
//       .title {
//         width: 100%;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//       }
//     }
//   }
//   .inner_left_right {
//     .inner_right {
//       color: #fe9900;
//     }
//   }
// }
